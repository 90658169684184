<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Applications" :to="{ name: 'all-state-applications' }"/>
      <b-breadcrumb-item text="Application" active/>
    </template>

    <template v-if="isEnabled" #actions="{ state }">
      <b-button v-if="state.editing" id="action-update" variant="primary" size="sm" class="d-inline-flex align-items-center" @click="updateApplication">
        <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50" /> Update
      </b-button>

    </template>
    <template #dropdown-options="{ state }">
      <b-dropdown-item v-if="isEnabled" @click="toggleEditable(state)">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
      <can do="delete" on="all-state-application">
        <b-dropdown-item @click="$refs.layout.confirmDelete(application, deleteApplication, cascadeConfirmDeleteOptions)">
          <feather-icon icon="Trash2Icon"/>
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>
      <b-dropdown-divider v-if="isEnabled"/>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #tour="{ tourName }">
      <app-tour :name="tourName" :steps="tour.steps" :callbacks="tour.callbacks"/>
    </template>

    <template #content="{ state }">
      <template v-if="error">
        <b-alert id="application-error" show variant="danger">
          <p>
            <b-icon-exclamation-circle class="mr-25"/>
            {{ error }}
          </p>
        </b-alert>
      </template>
      <template v-else>
        <b-row v-if="!state.loading">
          <b-col cols="12">
            <b-alert id="application-disabled" :show="!isEnabled" variant="primary">
            <p>
              <b-icon-info-circle class="mr-25"/>
              Editing an Application is currently disabled.
            </p>
            <small>
              If you believe you should still be able to edit an application, please
              <b-link :to="{ name: 'support' }"><u>contact us</u></b-link> requesting temporary access.
            </small>
          </b-alert>
            <b-tabs v-model="tabIndex" vertical card pills active-tab-class="pt-0 pr-0" nav-class="tab-selection">
            <can v-slot="{ allowed }" pass-through do="read" on="all-state-application[tab=details]">
              <b-tab id="details-tab" title="Details" :disabled="!allowed">
              <template #title>
                Details <b-badge v-if="tabIndex !== 0 && $refs.details.$refs.observer.flags.invalid" pill variant="danger" class="ml-05">{{ getValidationErrorCount('details') }}</b-badge>
              </template>
              <b-card id="details-card">
                <application-details ref="details"
                                     :current-user="user"
                                     :festival.sync="application.festival"
                                     :form.sync="application.form"
                                     :instrument.sync="application.instrument"
                                     :editable="state.editing && $can('update', 'all-state-application[tab=details]')"
                                     :tour-props="tour.props.details"/>
              </b-card>
            </b-tab>
            </can>
            <can v-slot="{ allowed }" pass-through do="read" on="all-state-application[tab=student]">
              <b-tab id="student-tab" :disabled="!allowed">
              <template #title>
                Student <b-badge v-if="tabIndex !== 1 && $refs.student.$refs.observer.flags.invalid" pill variant="danger" class="ml-05">{{ getValidationErrorCount('student') }}</b-badge>
              </template>
              <can do="update" on="all-state-application[tab=student]">
                <b-alert id="student-alert" :show="state.editing" variant="primary" dismissible>
                  <b-media no-body>
                    <b-media-aside class="mt-25">
                      <b-icon-info-circle/>
                    </b-media-aside>
                    <b-media-body>
                      Directly editing the student details is not allowed in the application view. <br/>
                      <small>
                        You can change the student associated to this application by selecting a different one from the dropdown, or
                        if you wish to edit the student details you can click <b-link class="underline" :to="{ name: 'all-state-student', params: { id: application.student.id }}"><u>here</u></b-link>.
                      </small>
                    </b-media-body>
                  </b-media>
                </b-alert>
              </can>

              <b-card id="student-card">
                <application-student ref="student"
                                     :current-user="user"
                                     :student.sync="application.student"
                                     :create-student="false"
                                     :existing-students-options="{ show: state.editing && $can('update', 'all-state-application[tab=student]'), clearable: false }"
                                     :editable="state.editing && $can('update', 'all-state-application[tab=student]')"/>
              </b-card>
            </b-tab>
            </can>
            <can v-slot="{ allowed }" pass-through do="read" on="all-state-application[tab=questions]">
              <b-tab id="questions-tab" title="Questions" :disabled="!allowed">
              <template #title>
                Questions <b-badge v-if="tabIndex !== 2 && $refs.questions.$refs.observer.flags.invalid" pill variant="danger" class="ml-05">{{ getValidationErrorCount('questions') }}</b-badge>
              </template>

              <b-card id="questions-card">
                <application-questions ref="questions"
                                       :form="application.form"
                                       :questions.sync="application.questions"
                                       :editable="state.editing && $can('update', 'all-state-application[tab=questions]')" />
              </b-card>
            </b-tab>
            </can>
            <can v-slot="{ allowed }" pass-through do="read" on="all-state-application[tab=grading]">
              <b-tab id="grading-tab" title="Grading" :disabled="!allowed">
              <template #title>
                Grading <b-badge v-if="tabIndex !== 3 && $refs.grading.$refs.observer.flags.invalid" pill variant="danger" class="ml-05">{{ getValidationErrorCount('grading') }}</b-badge>
              </template>
              <b-card id="grading-card">
                <application-grading ref="grading"
                                     :form="application.form"
                                     :student="application.student"
                                     :grading.sync="application.grading"
                                     :recommendation.sync="application.recommendation"
                                     :comments.sync="application.comments"
                                     :editable="state.editing && $can('update', 'all-state-application[tab=grading]')"
                                     :tour-props="tour.props.grading"/>
              </b-card>
            </b-tab>
            </can>
            <can do="manage" on="all-state-application">
              <b-tab id="teacher-tab" title="Teacher" lazy>
                <template #title>
                  <div class="d-flex w-100 justify-content-between">
                    Teacher <b-badge pill variant="danger" class="d-flex ml-05">admin</b-badge>
                  </div>
                </template>
                <b-card id="dev-card">
                  <application-teacher ref="teacher"
                                       :current-user="user"
                                       :app-id="application.id"
                                       :teacher.sync="application.teacher"
                                       :editable="state.editing"/>
                </b-card>
              </b-tab>
              <b-tab id="other-tab" title="Other" lazy>
                <template #title>
                  <div class="d-flex w-100 justify-content-between">
                    Other <b-badge pill variant="danger" class="d-flex ml-05">admin</b-badge>
                  </div>
                </template>
                <b-card id="other-card">
                  <application-other-tab ref="other" :application="application" :editable="state.editing" @update:createdAt="value => application.createdAt = value"/>
                </b-card>
              </b-tab>
            </can>
          </b-tabs>
        </b-col>
      </b-row>
      </template>
    </template>

    <template #debug>
      <debug title="Application" collapsed>{{ application }}</debug>
    </template>

  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationTeacher from '@/views/all-state/application/ApplicationTeacher.vue';
import print from '@/mixins/print.mixin';
import notify from '@/mixins/notify.mixin';
import AppTour from '@core/components/app-tour/AppTour.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getApplication, updateApplication, getUser} from '@/graphql/queries/application';
import { cascadeDeleteApplication, cascadeConfirmDeleteOptions} from '@/graphql/cascade/application';
import settingsMixin from '@/mixins/settings.mixin';
import ApplicationOtherTab from '@/views/all-state/application/ApplicationOtherTab.vue';

export default {
  components: {
    PageLayout,
    AppTour,
    ApplicationDetails,
    ApplicationStudent,
    ApplicationGrading,
    ApplicationQuestions,
    ApplicationTeacher,
    ApplicationOtherTab
  },
  mixins: [ print, notify, settingsMixin ],
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      user: this.currentUser,
      application: null,
      loading: true,
      tabIndex: 0,
      canManage: true,
      error: null,
      tour: {
        name: 'application-wizard',
        steps: [
          {
            target: '.tabs',
            header: { title: 'Application View' },
            content: this.isEnabled ? `
                <p>The Application view page is meant as a way for the user to view or edit an application that was previously submitted.</p>
                <p>The contents of this page are the same as creating a new application, it's just displayed differently to help differentiate what action is being performed.</p>
                <p class="text-danger font-small-3">For the purpose of this tour, the page has been switched into Edit Mode. Once the tour ends, the page will go back into View mode.</p>
              ` : `
                <p>The Application view page is meant as a way for the user to view or edit an application that was previously submitted.</p>
                <p>The contents of this page are the same as creating a new application, it's just displayed differently to help differentiate what action is being performed.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: 'ul[role="tablist"]',
            header: { title: 'Navigation' },
            content: `
               <p>Click a tab to switch between different sections of the application.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#details-card',
            header: { title: 'Application Details' },
            content: `
                <p>The Application Details section </p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 0
              resolve()
            })
          },
          {
            target: '#festivals-btn',
            header: { title: 'Festivals Toggle' },
            content: 'By default, only festivals associated to your schools are shown in the festivals dropdown. Click this button to toggle on All Festivals.',
            params: {
              placement: 'left',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 0
              resolve()
            })
          },
          {
            target: '#student-card',
            header: { title: 'Student Information' },
            content: `
                <p>This section displays information about the student associated to this application.</p>
                <p class="text-danger font-small-3">You can change the student associated to this application, but can not edit the student fields directly.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 1
              resolve()
            })
          },
          {
            target: '#existing-students-dropdown',
            header: { title: 'Change Student' },
            content: `
                <p>If for some reason a student is incorrectly associated to an application, you can change the association by selecting a different student. </p>
                <p class="text-danger font-small-3">If you need to make changes to the student fields, see the alert directly above this dropdown.</p>
            `,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 1
              resolve()
            })
          },
          {
            target: '#questions-card',
            header: { title: 'Questions' },
            content: `
                <p>This section displays questions associated to the Form field selected on the Application Details tab.</p>
                <p class="text-danger font-small-3">All questions are optional, however some questions once started must either be completed or deleted (ie: Organizations).</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 2
              resolve()
            })
          },
          {
            target: '#grading-card',
            header: { title: 'Grading' },
            content: `
                <p>This section displays the grading information based on the student's ability and comprehension of multiple skills.</p>
              `,
            params: {
              placement: 'auto',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tabIndex = 3
              resolve()
            })
          },
          {
            target: '#grading-dropdown',
            header: { title: 'Quick Grading' },
            content: 'Instead of selecting the same option manually for each category, clicking this button will display a set of options to apply to all categories.',
            params: {
              placement: 'bottom-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = false;
              resolve()
            })
          },
          /*{
            target: '#grading-dropdown-group',
            header: { title: 'Grading Options' },
            content: `
              <p>Clicking any of these options will apply its value to all categories</p>
          `,
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = true;
              resolve()
            })
          },*/
          {
            target: '#action-update',
            header: { title: 'Update' },
            content: `
                <p>Once you've finished making edits, click this button to save your changes.</p>
            `,
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = false;
              resolve()
            })
          },
        ],
        callbacks: {
          onStart: () => {
            this.tabIndex = 0
            this.$refs.layout.state.editing = this.isEnabled
            this.tour.props.details.festivalsBtn.show = true
          },
          onSkip: () => {
            this.$refs.layout.state.editing = false
            this.tour.props.details.festivalsBtn.show = false
          },
          onFinish: () => {
            this.$refs.layout.state.editing = false
            this.tour.props.details.festivalsBtn.show = false
          },
          onStop: () => {
            this.$refs.layout.state.editing = false
            this.tour.props.details.festivalsBtn.show = false
          },
        },
        props: {
          details: {
            festivalsBtn: {
              show: false
            }
          },
          grading: {
            options: {
              show: false
            },
          }
        }
      },
      icon: 'fas fa-clipboard',
      cascadeConfirmDeleteOptions
    }
  },
  watch: {
    tabIndex() {
      this.validateDetailsTab().catch(e => e)
      this.validateStudentTab().catch(e => e)
      this.validateQuestionsTab().catch(e => e)
      this.validateGradingTab().catch(e => e)
    },
    isEnabled(value) {
      if(this.$refs.layout.state.editing && !value) {
        this.$refs.layout.state.editing = false
      }
    }
  },
  async mounted() {
    await this.getApplication();
    await this.addConditionalTourSteps()
    await this.validateDetailsTab().catch(e => e)
    await this.validateStudentTab().catch(e => e)
    await this.validateQuestionsTab().catch(e => e)
    await this.validateGradingTab().catch(e => e)
  },
  methods: {
    async refresh() {
      this.$refs.layout.state.loading = true
      this.error = null
      await this.getApplication()
      this.$refs.layout.state.loading = false
    },
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
      this.user.schools.items.sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
    },
    async getApplication() {
      const response = await API.graphql(graphqlOperation(getApplication, { id: this.id }));
      this.application = response.data.getApplication
      if(this.application) {
        /** Check Current Year **/
        if(!this.isCreatedAtInCurrentYear(this.application.createdAt)) {
          this.error = `You are currently viewing ${this.$store.state.settings.app.current.title} data. This application was created in ${new Date(this.application.createdAt).getFullYear()}. Switch to that year to view this application.`
        }

        /** Check Permissions **/
        if (this.user.groups.includes('Teacher')) {
          const hasError = !this.application.teacherID.includes(this.user.id) //|| !this.user.schools.items.some(item => item.school.id === this.application.student.school.id)
          if(hasError) { this.error = 'You don\'t have permission to manage this application.' }
        }
      }
      else {
        this.error = 'Application Doesn\'t Exist'
      }
      this.$refs.layout.state.loading = false
    },
    async patchApplication(input) {
      try {
        await API.graphql(graphqlOperation(updateApplication, { input: input } ));
        this.notify({ title: 'Success', text: 'Application was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Application failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateApplication() {
      const isValid = Promise.all([this.validateDetailsTab(), this.validateStudentTab(), this.validateQuestionsTab(), this.validateGradingTab()])
      isValid.then(async () => {
        const input = {
          id: this.application.id,
          applicationFestivalId: this.application.festival.id,
          applicationFormId: this.application.form.id,
          applicationInstrumentId: this.application.instrument.id,
          studentApplicationsId: this.application.student.id,
          questions: this.application.questions,
          grading: this.application.grading,
          recommendation: this.application.recommendation,
          comments: this.application.comments,
        }
        await this.patchApplication(input)
      })
          .catch(() => {
            //this.state.error = 'Validation Error'
            this.notify({ title: 'Error', text: 'Application did not validate', icon: 'fas fa-clipboard', variant: 'danger' })
          })
    },
    async deleteApplication(application, swalCallback) {
      try{
        await this.cascadeDeleteApplication(application.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Application was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'all-state-applications' })
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Application failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteApplication,


    validateDetailsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.details.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateStudentTab() {
      return new Promise((resolve, reject) => {
        this.$refs.student.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateQuestionsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.questions.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateGradingTab() {
      return new Promise((resolve, reject) => {
        this.$refs.grading.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getValidationErrorCount(ref) {
      return Object.keys(this.$refs[ref].$refs.observer.errors).reduce((previous, key) => previous + this.$refs[ref].$refs.observer.errors[key].length, 0)
    },

    async addConditionalTourSteps() {
      if(!this.isEnabled) {
        this.tour.steps.splice(0, 0, {
          target: '#application-disabled',
          header: { title: 'Disabled' },
          content: `
                ???
            `,
          params: { placement: 'auto', enableScrolling: false, highlight: true},
        })
        this.tour.steps.splice(this.tour.steps.findIndex(step => step.target === '#existing-students-dropdown'), 1)
        this.tour.steps.splice(-1) //remove updating step
      }
      if(!this.canManage) {
        this.tour.steps = []
        this.tour.steps.push({
          target: '#application-error',
          header: { title: 'Error' },
          content: `
                ???
              `,
          params: { placement: 'auto', enableScrolling: false, highlight: true },
        })
      }
    },

    toggleEditable(state) {
      if(this.isEnabled) {
        state.editing = !state.editing
      }
      else {
        this.error.visible = true
        this.error.message = 'Editing is currently disabled'
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@core/assets/fonts/feather/iconfont.css';
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';

.tab-selection {
  height: fit-content!important;
  border: 1px solid transparent!important;
  border-radius: 0.428rem!important;
}

.tab-selection.nav-pills .nav-link {
  justify-content: start!important;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .nav-item a span.badge {
    font-size: 60%;
  }
}
</style>
