<template>
  <b-row>
    <b-col cols="12">
      <template v-if="!loading">
        <template v-if="$route.name === 'all-state-application'">
          <application-view :id="id" :current-user="user" :is-enabled="isEnabled"></application-view>
        </template>
        <template v-if="$route.name === 'all-state-application-create'">

          <application-wizard v-if="isEnabled" :current-user="user" />
          <template v-else>
            <b-alert v-if="setting && setting.message && setting.message.enabled" show variant="primary">
              <p>
                <b-icon-info-circle class="mr-25"/>
                New Applications are currently disabled.
              </p>
              <small>{{ setting.message.text }}</small>
            </b-alert>
            <b-alert v-else show variant="primary">
              <p> <b-icon-info-circle class="mr-25"/> New Applications are currently disabled.</p>
              <small>
                If you believe you should still be able to create a new application, please
                <b-link :to="{ name: 'support' }"><u>contact us</u></b-link> requesting temporary access.
              </small>
            </b-alert>
          </template>
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import notify from '@/mixins/notify.mixin';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import ApplicationView from '@/views/all-state/application/ApplicationView.vue';
import ApplicationWizard from '@/views/all-state/application/ApplicationWizard.vue'
import {getUser, getSetting, onUpdateSetting } from '@/graphql/queries/application';

export default {
  components: {
    ApplicationView,
    ApplicationWizard,
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      user: null,
      setting: null,
      subscriptions: {
        onUpdate: null
      }
    }
  },
  computed: {
    isEnabled() {
      return this.setting?.enabled
          || this.setting?.override?.groups?.some(group => this.user.groups.includes(group))
          || this.setting?.override?.users?.some(user => user.id === this.user.id)
          || this.$can('manage')
    },
  },
  async mounted() {
    await this.getCurrentUser()
    await this.getSetting()
    await this.onUpdateSetting()
  },
  beforeDestroy() {
    if(this.subscriptions?.onUpdate) {
      this.subscriptions.onUpdate.unsubscribe()
    }
  },
  methods: {
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
      this.user.schools.items.sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
    },
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'application' }));
      const setting = response.data.getSetting
      if(setting) {
        this.setting = JSON.parse(setting.value)
      }
      this.loading = false
    },
    async onUpdateSetting() {
      this.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateSetting)).subscribe((sourceData) => {
        const setting = sourceData.value.data.onUpdateSetting
        if (setting?.key === 'application') {
          this.syncNotification()
          this.setting = JSON.parse(setting.value)
        }
      });
    },
  }
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-wizard.scss';
  @import '~@core/scss/vue/libs/vue-select.scss';
  .app-label {
    color: #5e5873;
    font-size: 0.857rem;
    display: inline-block;
  }
</style>
