<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Other Information</h5>
        <small class="text-danger">Allows admins to change the other information about the application.</small>
      </b-col>
      <b-col align-self="end">
        <b-form-group label="Created At" label-class="pt-1" class="mb-0">
          <b-input-group class="input-group-merge bg-white input-group-border">
            <b-input-group-prepend>
              <b-button size="sm" variant="transparent" title="Toggle" data-toggle>
                <font-awesome-icon icon="fa-solid fa-calendar" />
                <span aria-hidden="true" class="sr-only">Toggle</span>
              </b-button>
            </b-input-group-prepend>
            <flat-pickr
                v-model="createdAt"
                :config="createdAtConfig"
                class="form-control bg-white mb-0"
                placeholder="Select date"
                name="date"/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto" align-self="end">
        <b-button :disabled="updating" variant="primary" @click="updateCreatedAt">
          <template v-if="updating">
            <b-spinner label="Loading..." small></b-spinner> Updating
          </template>
          <template v-else>
            Update
          </template>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import {API, graphqlOperation} from 'aws-amplify';
import {updateApplication} from '@/graphql/queries/application';
import notify from '@/mixins/notify.mixin';

export default {
  name: 'ApplicationOtherTab',
  components: {
    flatPickr
  },
  mixins: [notify],
  props: {
    application: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      createdAt: this.application.createdAt,
      createdAtConfig: {
        wrap: true,
        altFormat: 'l, F d, Y',
        altInput: true,
        enableTime: true,
        enableSeconds: true,
        defaultHour: 0,
        defaultMinute: 1,
        maxDate: new Date().setDate(new Date().getDate() + 30),
        dateFormat: 'Z',
        altInputClass: 'form-control bg-white border-0',
      },
      icon: 'fas fa-clipboard',
      updating: false
    }
  },
  methods: {
    async updateCreatedAt() {
      this.updating = true;
      try {
        const input = {
          id: this.application.id,
          createdAt: this.createdAt
        }
        await API.graphql(graphqlOperation(updateApplication, { input: input } ));
        this.$emit('update:createdAt', this.createdAt);
        this.notify({ title: 'Success', text: 'Application was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Application failed to update', icon: this.icon, variant: 'danger'});
      }
      finally {
        this.updating = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

html[dir='rtl'] {
  .flatpickr-calendar {
    .flatpickr-time {
      display: none!important;
      height: 0!important;
    }
  }
}


.input-group-border {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.input-group:focus-within {
  border-color: var(--primary);
}

</style>
