<template>
  <page-layout ref="layout" @refresh="refresh">
    <template v-if="$route.query.clone" #breadcrumbs>
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Students" :to="{ name: 'all-state-students' }" />
      <b-breadcrumb-item text="New Application" active />
      <b-breadcrumb-item text="Clone" active />
    </template>
    <template v-else #breadcrumbs>
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Students" :to="{ name: 'all-state-students' }" />
      <b-breadcrumb-item text="New Application" active />
    </template>

    <template #tour="{ tourName }">
      <app-tour :name="tourName" :steps="tour.steps" :callbacks="tour.callbacks"/>
    </template>

    <template #content>
      <b-overlay :show="state.loading" variant="white" opacity="1" :rounded="true">
        <template v-if="state.cloning" #overlay>
          <div class="text-center">
            <font-awesome-icon icon="fa-solid fa-copy" size="4x" class="mb-1 fa-fade" style="--fa-animation-duration: 2s; --fa-fade-opacity: 0.6;" />
            <p id="cancel-label">Cloning, please wait...</p>
          </div>
        </template>
        <b-card v-if="state.loading"><b-skeleton-table/></b-card>

        <b-alert v-if="state.error" show dismissible variant="danger">
          {{ state.error }}
        </b-alert>

        <form-wizard v-if="!state.loading"
                     ref="wizard"
                     :start-index="0"
                     :title="null"
                     :subtitle="null"
                     color="#7367F0"
                     shape="square"
                     finish-button-text="Submit"
                     back-button-text="Previous"
                     class="mb-3"
                     @on-complete="createApplication">

          <!-- Application Details tab -->
          <tab-content v-if="!state.loading" title="Application Details" icon="feather icon-music" :before-change="validateDetailsTab">
            <application-details ref="details"
                                 :current-user="user"
                                 :festival.sync="application.festival"
                                 :form.sync="application.form"
                                 :instrument.sync="application.instrument"
                                 :tour-props.sync="tour.props.details"/>
          </tab-content>

          <!-- Student Details tab -->
          <tab-content v-if="!state.loading" title="Student Information" icon="feather icon-user" lazy :before-change="validateStudentTab">
            <application-student ref="student"
                                 :current-user="user"
                                 :student.sync="application.student" />
          </tab-content>

          <!-- Questions  -->
          <tab-content v-if="!state.loading" title="Questions" icon="feather icon-file-text" :before-change="validateQuestionsTab">
            <application-questions ref="questions"
                                   :form="application.form"
                                   :questions.sync="application.questions" />
          </tab-content>

          <!-- Grading -->
          <tab-content v-if="!state.loading" title="Grading" icon="feather icon-star" :before-change="validateGradingTab">
            <application-grading ref="grading"
                                 :form="application.form"
                                 :student="application.student"
                                 :grading.sync="application.grading"
                                 :recommendation.sync="application.recommendation"
                                 :comments.sync="application.comments"
                                 :tour-props.sync="tour.props.grading"/>
          </tab-content>


          <template slot="footer" scope="props">
            <div class="wizard-footer-left">
              <b-button v-if="props.activeTabIndex > 0 && !props.isLastStep" :style="props.fillButtonStyle" @click="$refs.wizard.prevTab()">Previous</b-button>
            </div>
            <div class="wizard-footer-right">
              <b-button v-if="!props.isLastStep" variant="primary" :style="props.fillButtonStyle" class="wizard-footer-right" @click="$refs.wizard.nextTab()">Next</b-button>

              <b-button v-else :disabled="state.submitting" variant="primary" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" @click="createApplication">
                <b-icon v-if="state.submitting" icon="arrow-clockwise" animation="spin-pulse" font-scale="1"></b-icon> Submit
              </b-button>
            </div>
          </template>
        </form-wizard>
      </b-overlay>
    </template>

    <template #debug>
      <debug collapsed>
        {{ application }}
      </debug>
    </template>

  </page-layout>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import {API, Auth, graphqlOperation} from 'aws-amplify';
import { getUser, getApplication, createApplication, createStudent } from '@/graphql/queries/application';
import { uuid } from 'vue-uuid';
import notify from '@/mixins/notify.mixin';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import PageLayout from '@/components/PageLayout.vue';
import AppTour from '@core/components/app-tour/AppTour.vue';
import {createInvoice} from '@/graphql/mutations';

export default {
  components: {
    PageLayout,
    AppTour,
    ApplicationGrading,
    ApplicationQuestions,
    ApplicationDetails,
    ApplicationStudent,
    FormWizard,
    TabContent,
  },
  directives: { },
  mixins: [ notify ],
  props: {
    currentUser: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      user: this.currentUser,
      application: {
        student: {
          id: null,
          name: {
            first: null,
            last: null
          },
          grade: 0,
          dob: '',
          sex: null,
          address: {
            line1: null,
            line2: null,
            city: null,
            county: null,
            state: null,
            zip: null
          },
          phone: {
            type: null,
            number: null,
            ext: null
          },
          email: {
            type: null,
            address: null
          }
        },
        questions: {
          lastYear: {
            attended: false,
            group: null,
            part: null,
            chair: null
          },
          moreThanOne: {
            response: false,
            instruments: []
          },
          organizations: {
            school: [],
            other: []
          },
          experience: {
            instrument: {
              years: null,
              doubles: null
            },
            vocalJazzAccompaniment: false,
            jazzEnsemble: false,
            orchestral: false
          },
          previousSoloRating: {
            ninth: {
              level: null,
              grade: null
            },
            tenth: {
              level: null,
              grade: null
            }
          }
        },
      },
      tour: {
        name: 'application-wizard',
        steps: [
          {
            target: '.vue-form-wizard',
            header: { title: 'New Application Wizard' },
            content: 'The application form has been split up into four sections. This Wizard will take you through each of them, ensuring all required fields are filled out.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '.wizard-navigation ul',
            header: { title: 'Navigation Tabs' },
            content: `
              <p>There are two ways to navigate the Wizard, through the Navigation tabs or by click the Next and Previous buttons.</p>
              <p class="text-danger font-small-3">Tabs will be disabled if you have not progressed through the Next button.</p>
            `,
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
          },
          /*{
            target: 'a:has(#step-ApplicationDetails0)',
            header: { title: 'Application Details' },
            content: 'If you ever want to get back to the home page, you can click the logo.',
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: 'a:has(#step-StudentInformation1)',
            header: { title: 'Student Information' },
            content: 'If you ever want to get back to the home page, you can click the logo.',
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: 'a:has(#step-Questions2)',
            header: { title: 'Application Questions' },
            content: 'If you ever want to get back to the home page, you can click the logo.',
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: 'a:has(#step-Grading3)',
            header: { title: 'Application Grading' },
            content: 'If you ever want to get back to the home page, you can click the logo.',
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
          },
*/
          {
            target: '.wizard-tab-content',
            header: { title: 'Application Details Form' },
            content: 'The Application Details provides information needed in other parts of the application.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.details.festivalsBtn.show = true
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0)
              resolve()
            })
          },
          {
            target: '#festivals-btn',
            header: { title: 'Festivals Toggle' },
            content: 'By default, only festivals associated to your school are shown in the festivals dropdown. To see all festivals, click this button. ',
            params: {
              placement: 'left',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.details.festivalsBtn.show = true
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0)
              resolve()
            })
          },

          {
            target: '.wizard-footer-right',
            header: { title: 'Next' },
            content: 'When your done filling out the current section, click this button to proceed.',
            params: {
              placement: 'left',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '.wizard-footer-left',
            header: { title: 'Previous' },
            content: 'If you need to back to the previous section, click this button.',
            params: {
              placement: 'right',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.details.festivalsBtn.show = true
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 1)
              resolve()
            })
          },

          {
            target: '.wizard-tab-content',
            header: { title: 'Student Information Form' },
            content: `
                <p>When entering Student Information, there are two options: Selecting an Existing Student or creating a New Student.</p>
            `,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.details.festivalsBtn.show = false
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 1)
              resolve()
            })
          },
          {
            target: '#new-student-btn',
            header: { title: 'New Student' },
            content: `
                <p>If this is your first time submitting an application for a particular student you should click the New Student button.</p>
            `,
            params: {
              placement: 'bottom-start',
              enableScrolling: false,
              highlight: true
            },
          },
          {
            target: '#existing-students-dropdown',
            header: { title: 'Existing Students' },
            content: `
                <p>If this is an application for a student you have already submitted for, you should select a Student from the Existing Student dropdown. </p>
                <p class="text-danger font-small-3">All fields will be disabled. If you need to make changes to an existing student, that is done elsewhere.</p>
            `,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 1)
              resolve()
            })
          },
          {
            target: '.wizard-tab-content',
            header: { title: 'Questions Form' },
            content: 'All questions are optional and were determined by the Form that was selected on the Application Details tab.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 2)
              resolve()
            })
          },
          {
            target: '.wizard-tab-content',
            header: { title: 'Grading Form' },
            content: 'Describes the students skills and understanding in a variety of categories.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              if(type === 'next') {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 3)
              }
              else {
                this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 2)
              }
              resolve()
            })
          },
          {
            target: '#grading-dropdown',
            header: { title: 'Quick Grading' },
            content: 'Instead of selecting the same option manually for each category, clicking this button will display a set of options to apply to all categories.',
            params: {
              placement: 'bottom-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = false;
              resolve()
            })
          },
          {
            target: '#grading-dropdown-group',
            header: { title: 'Grading Options' },
            content: `
                <p>Clicking any of these options will apply its value to all categories</p>
            `,
            params: {
              placement: 'left-start',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = true;
              resolve()
            })
          },
          {
            target: '.finish-button',
            header: { title: 'Submit' },
            content: 'When all required fields have been filled out and the forms have passed a validation check, the application will be submitted.',
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true
            },
            before: type => new Promise((resolve, reject) => {
              this.tour.props.grading.options.show = false;
              resolve()
            })
          },

        ],
        callbacks: {
          onSkip: () => this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0),
          onFinish: () => this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0),
          onStop: () => this.$refs.wizard.changeTab(this.$refs.wizard.activeTabIndex, 0),
        },
        props: {
          details: {
            festivalsBtn: {
              show: false
            }
          },
          grading: {
            options: {
              show: false
            },
          }
        }
      },
      state: {
        loading: true,
        submitting: false,
        cloning: false,
        error: null
      }
    }
  },
  async mounted() {
    this.$refs.layout.state.loading = false
    if(this.$route.query.clone) {
      this.state.cloning = true
      await this.getApplication(this.$route.query.clone)
    }
    this.state.loading = false
  },
  methods: {
    async refresh() {
      this.state.loading = true
      await this.getCurrentUser()
      this.state.loading = false
    },
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
      this.user.schools.items.sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
    },
    async getApplication(id) {
      await API.graphql(graphqlOperation(getApplication, { id: id })).then(response => {
        if(!response.data.getApplication) {
          throw Error('Failed to clone record')
        }
        this.application = response.data.getApplication
        this.application.id = uuid.v4()
      })
      .catch(error => {
        this.state.error = error
      })
      .finally(() => {
        this.state.cloning = false
      })
    },
    async createApplication() {
      this.state.submitting = true //Prevent double submission! - will disable the submit button
      const isValid = Promise.all([this.validateDetailsTab(), this.validateStudentTab(), this.validateQuestionsTab(), this.validateGradingTab()])
      isValid.then(async () => {
        const applicationId = uuid.v4()
        let studentId = uuid.v4()
        let studentExists = false;

        if (this.application.student.id) {
          studentExists = true
          studentId = this.application.student.id
        }

        const applicationInput = {
          id: applicationId,
          studentApplicationsId: studentId,
          applicationFestivalId: this.application.festival.id,
          applicationFormId: this.application.form.id,
          applicationInstrumentId: this.application.instrument.id,
          teacherID: this.user.id,

          grading: this.application.grading,
          questions: this.application.questions,
          recommendation: this.application.recommendation,
          comments: this.application.comments,
          ranking: {
            score: 0,
            local: 0,
            state: 0
          },
          //createdAt: '2024-01-01T15:52:56Z',
        }

        if (studentExists) {
          await API.graphql(graphqlOperation(createApplication, {input: applicationInput}))
            .then(async () => {
              await this.notify({ title: 'Success', text: 'Application was successfully created', icon: 'fas fa-clipboard', variant: 'success'});
              await this.$router.push({name: 'all-state-applications'})
            })
            .catch((e) => {
              this.state.error = e
            })
        }
        else {
          //const invoiceId = uuid.v4()
          const studentInput = {
            id: studentId,
            name: this.application.student.name,
            address: this.application.student.address,
            grade: this.application.student.grade,
            dob: this.application.student.dob,
            email: this.application.student.email,
            phone: this.application.student.phone,
            sex: this.application.student.sex,
            schoolID: this.application.student.school.id,
            //studentInvoiceId: invoiceId
          }
          //const invoiceInput = { id: invoiceId, invoiceStudentId: studentId }

          await API.graphql(graphqlOperation(createStudent, {input: studentInput}))
            .then(async () => {
              //await API.graphql(graphqlOperation(createInvoice, {input: invoiceInput})).catch((e) => { this.state.error = e })
              await API.graphql(graphqlOperation(createApplication, {input: applicationInput})).catch((e) => { this.state.error = e })
              await this.notify({ title: 'Success', text: 'Application was successfully created', icon: 'fas fa-graduation-cap', variant: 'success' });
              await this.$router.push({name: 'all-state-applications'})
            })
            .catch((e) => {
              this.state.error = e
            })
        }
      })
      .catch(() => {
        this.state.error = 'Validation Error'
        this.notify({ title: 'Error', text: 'Application did not validate', icon: 'fas fa-clipboard', variant: 'danger' })
      })
      .finally(() => { this.state.submitting = false })
    },

    validateDetailsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.details.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateStudentTab() {
      return new Promise((resolve, reject) => {
        this.$refs.student.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateQuestionsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.questions.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateGradingTab() {
      return new Promise((resolve, reject) => {
        this.$refs.grading.$refs.observer.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  }
}
</script>
