<template>
  <validation-observer ref="observer" tag="form">
    <b-row v-if="showHeading">
      <b-col cols="12" class="mb-2">
				<h5 class="mb-0">Teacher Information</h5>
				<small class="text-danger">Allows admins to change the teacher associated with the application.</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col align-self="center">
        <v-select id="teacher"
                  v-model="application.teacher"
                  :loading="options.users.loading"
                  :options="users" label="name"
                  :filter="filterUsers"
                  :select-on-tab="true"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="syncTeacher"
        >
          <template #option="{ name, username }">
            <div>{{ name.first }} {{ name.last }}</div>
            <small>@{{ username }}</small>
          </template>
          <template #selected-option="{ name }">
            <span>{{ name.first }} {{ name.last }}</span>
          </template>
        </v-select>
      </b-col>
      <b-col cols="auto" align-self="center">
        <b-button variant="primary" :disabled="application.teacher.id === application.initial.id" @click="patchApplication({ id: appId, teacherID: application.teacher.id })">Update</b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {TabContent} from 'vue-form-wizard';
import vSelect from 'vue-select';
import {API, graphqlOperation} from 'aws-amplify';

import Fuse from 'fuse.js';
import { listUsers } from './queries/application-teacher';
import {updateApplication} from '@/graphql/queries/application';
import notify from '@/mixins/notify.mixin';

export default {
  name: 'ApplicationTeacher',
  components: {
    TabContent,
    vSelect,
  },
  mixins: [notify],
  props: {
    currentUser: {
      type: Object,
      default: null
    },
    appId: {
      type: String,
      required: true
    },
    teacher: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    showHeading: {
        type: Boolean,
        default: true
    }
  },
  data() {
    return {
      user: this.currentUser,
      application: {
        teacher: {
          id: this.teacher?.id || null,
          username: this.teacher?.username || null,
          name: {
            first: this.teacher?.name?.first || null,
            last: this.teacher?.name?.last || null
          },
          schools: {
            items: this.teacher?.schools?.items || [],
          }
        },
        initial: {
          id: this.teacher?.id || null,
          username: this.teacher?.username || null,
          name: {
            first: this.teacher?.name?.first || null,
            last: this.teacher?.name?.last || null
          },
          schools: {
            items: this.teacher?.schools?.items || [],
          }
        },
      },
      options: {
        users: {
          loading: false,
          items: [],
        },
      },
      icon: 'fas fa-clipboard'
    }
  },
  computed: {
    users() {
      if(this.options.users.items.length === 0) {
        this.listUsers()
        return []
      }
      return this.options.users.items
    },
  },
  methods: {
    async listUsers(nextToken, pagedUsers) {
      this.options.users.loading = true
      const users = pagedUsers || []
      const response = await API.graphql(graphqlOperation(listUsers, { limit: 500, nextToken: nextToken }));
      users.push(...response.data.listUsers.items);

      if(response.data.listUsers.nextToken) {
        await this.listUsers(response.data.listUsers.nextToken, users)
      }
      else {
        this.options.users.items = users.sort((a, b) => a?.name?.first?.localeCompare(b?.name?.first) || a?.name?.last?.localeCompare(b?.name?.last) )
        this.options.users.loading = false
      }
    },

    async patchApplication(input) {
      try {
        await API.graphql(graphqlOperation(updateApplication, { input: input } ));
        this.application.initial = this.application.teacher
        this.notify({ title: 'Success', text: 'Application was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Application failed to update', icon: this.icon, variant: 'danger'});
      }
    },

    syncTeacher() {
      this.$emit('update:teacher', this.application.teacher)
    },


    filterUsers(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.first', 'name.name', 'username'],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.v-select-inline {
  display: inline-block;
  width: auto;
  min-width: 30rem;
}

[dir] .vs__dropdown-menu {
  width: 100%!important;
}
</style>
