// eslint-disable-next-line import/prefer-default-export
export const listUsers = /* GraphQL */ `
    query ListUsers(
        $id: ID
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listUsers(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                username
                name {
                    first
                    last
                }
                schools {
                    items {
                        school {
                            id
                            beds
                            districtSchoolsId
                            schoolZoneId
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
